<template>
    <div
        class="line-item"
        :class="{ _big: big, _bold: bold, 'line-item__head-item': headItem, _active: active }"
        @click="actionClick"
    >
        <Icon :icon="icon" @click.stop="clickIcon" v-if="position === 'left' && icon" />
        <div class="line-item__text">
            <div class="line-item__title">
                <p class="line-item__inner" v-html="item.title || item.key" />
                <p class="line-item__subdescription" v-if="item.sub_description && showDescription">
                    {{ item.sub_description }}
                </p>
            </div>
            <p
                class="line-item__description mt-5"
                v-html="item.description"
                v-if="item.description && showDescription"
            />
        </div>

        <Icon :icon="icon" @click.stop="clickIcon" v-if="position === 'right' && icon" class="ml-a" />
        <Icon icon="check" class="ml-a check__icon" @click.stop="clickIcon" v-if="isCheckable" />
    </div>
</template>

<script>
import { defineComponent } from "vue";

import { Icon } from "../Icon";

const LineItem = defineComponent({
    name: "LineItem",
    props: {
        item: {
            type: Object,
        },
        big: {
            type: Boolean,
        },
        bold: {
            type: Boolean,
        },
        headItem: {
            type: Boolean,
        },
        icon: {
            type: String,
        },
        position: {
            type: String,
            default: "right",
        },
        active: {
            type: Boolean,
        },
        type: {
            type: String,
            default: "second",
        },
        action: {
            type: String,
        },
        isCheckable: {
            type: Boolean,
        },
        showDescription: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        Icon,
    },
    setup(props, { emit }) {
        const clickIcon = () => {
            emit("clickIcon", props.item);
        };

        const actionClick = () => {
            const { item } = props;
            if (item.action || props.action) {
                emit(item.action || props.action, item);
            }
            if (item.url) {
                window.location.href = props.item.url;
            }
        };

        return { actionClick, clickIcon };
    },
});
export default LineItem;
</script>

<style lang="scss">
.custom {
    @import "./line-item";
}
</style>
