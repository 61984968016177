<template>
    <header class="custom-header">
        <div class="custom-container">
            <div class="custom-header__info">
                <a href="/">
                    <div class="custom-header__logo">
                        <img :src="require('@/shared/ui/assets/images/logo.webp')" alt="Logo" />
                    </div>
                </a>
                <div class="custom-header__actions">
                    <NotificationCenterWrapper />
                    <search-bar />
                    <a
                        class="link"
                        href="/company/index"
                        :class="{ link_active: activeUrl.includes('company/index') }"
                        v-if="user.hasOfficeRole"
                    >
                        <Icon icon="cog" />
                    </a>
                    <UserDropdown :user="user" />
                </div>
            </div>
            <div class="custom-header__links">
                <PageHeaderLink
                    v-for="link of filteredLinks"
                    :key="'link-' + link.id"
                    :link="link"
                    :user="user"
                    :target="link.target"
                    :activeUrl="activeUrl"
                    :isStarterPlan="isStarterPlan"
                    :noIndex="link.noIndex"
                    @openAccessModal="accessModalStore.openAccessModal('core')"
                />
                <a class="create_new ml-a" @click="openCreateModal">
                    <CustomButton icon="plus" position="right" small :text="i18n.t('header.pages.create_new')" />
                </a>
            </div>
        </div>
        <div class="impersonate" v-if="user.isImpersonate">
            <div class="custom-container">
                <TextHead as="h6" bold color="white" class="impersonate__text">
                    {{ i18n.t("header.info.impersonate") }}
                </TextHead>
                <a class="impersonate__link" href="/user/stop_impersonate">
                    <CustomButton small position="right" icon="right" :text="i18n.t('buttons.stop')" />
                </a>
            </div>
        </div>
    </header>
    <CreateNew v-model:visible="createModal.visible" />
</template>

<script>
import { ref, computed, onMounted, reactive } from "vue";
import { useUserStore, useAccessModal } from "@/shared/model";
import { useI18n } from "vue-i18n";

import { CustomButton, Icon, TextHead } from "@/shared/ui";
import SearchBar from "@/features/SearchBar";
import NotificationCenterWrapper from "@/features/NotificationCenterWrapper";
import UserDropdown from "@/features/UserDropdown";
import CreateNew from "../CreateNew";
import PageHeaderLink from "./overview/PageHeaderLink.vue";

const PageHeader = {
    name: "PageHeader",
    components: {
        SearchBar,
        Icon,
        UserDropdown,
        CustomButton,
        TextHead,
        NotificationCenterWrapper,
        CreateNew,
        PageHeaderLink,
    },
    setup() {
        const i18n = useI18n();
        const store = useUserStore();
        const accessModalStore = useAccessModal();

        const createModal = reactive({ visible: false, data: {} });

        const user = computed(() => {
            return {
                hasOfficeRole: store.user?.hasOfficeRole,
                isImpersonate: store.user?.impersonate,
                currencyType: store.company?.default_currency,
            };
        });

        const isStarterPlan = computed(() => store.isStarterPlan);

        const activeUrl = computed(() => {
            if (window.location.pathname == "/") {
                return "/dashboard";
            }
            return window.location.pathname;
        });

        const links = ref([
            { id: 0, title: i18n.t("header.pages.dashboard"), href: "/dashboard/index" },
            { id: 1, title: i18n.t("header.pages.customers"), href: "/customer/index" },
            { id: 2, title: i18n.t("header.pages.propertyes"), href: "/property/index" },
            { id: 3, title: i18n.t("header.pages.jobs"), href: "/job/index" },
            {
                id: 4,
                title: i18n.t("header.pages.invoices"),
                href: "/invoice/index",
                notInStarterPlan: true,
            },
            {
                id: 5,
                title: i18n.t("header.pages.reports"),
                href: "/report/index",
                needOfficeRole: true,
                notInStarterPlan: true,
            },
            {
                id: 6,
                title: i18n.t("header.pages.calendar"),
                href: "/calendar/index",
                notInStarterPlan: true,
            },
            {
                id: 6,
                title: i18n.t("header.pages.help_centre"),
                href: "https://help.gasengineersoftware.co.uk/en/",
                target: "_blank",
            },
        ]);

        const filteredLinks = computed(() =>
            links.value.filter((link) => (link.needOfficeRole && user.value.hasOfficeRole) || !link.needOfficeRole)
        );

        const openCreateModal = () => {
            createModal.visible = true;
        };

        onMounted(async () => {
            const app = document.querySelector("#app");

            if (window.innerWidth <= 1024) {
                const meta = document.createElement("meta");
                meta.setAttribute("name", "viewport");
                meta.setAttribute("content", "width=1024");
                document.head.appendChild(meta);
            }

            app.classList.add("_hidden");
            await store.setUser();

            if (
                !user.value.hasOfficeRole &&
                window.location.href.includes("/company") &&
                !window.location.href.includes("/users") &&
                !window.location.href.includes("/subscription")
            ) {
                window.location.href = "/";
            } else {
                setTimeout(() => {
                    document.querySelector(".page-loader")?.classList.add("disabled");
                    app.classList.remove("_hidden");
                }, 300);
            }
        });

        return {
            links,
            filteredLinks,
            isStarterPlan,
            activeUrl,
            i18n,
            user,
            createModal,
            accessModalStore,
            openCreateModal,
        };
    },
};

export default PageHeader;
</script>

<style lang="scss">
@import "./page-header";
</style>
