<template>
    <a
        :href="currentLink"
        class="custom-header__link"
        :target="target"
        :class="{
            active: activeUrl.includes(link.href),
            'not-access': isDisabled,
        }"
        @click="openAccessModal"
    >
        {{ link.title }}
        <Icon icon="lock_empty" v-if="isDisabled" />
    </a>
</template>

<script>
import { computed, defineComponent } from "vue";
import { Icon } from "@/shared/ui";

export default defineComponent({
    name: "PageHeaderLink",
    props: {
        link: {
            type: Object,
        },
        activeUrl: {
            type: String,
        },
        target: {
            type: [String, null],
            default: "",
        },
        user: {
            type: Object,
        },
        isStarterPlan: {
            type: Boolean,
        },
    },
    components: {
        Icon,
    },

    setup(props, { emit }) {
        const isDisabled = computed(() => props.link.notInStarterPlan && props.isStarterPlan);

        const openAccessModal = () => {
            if (isDisabled.value) {
                emit("openAccessModal");
            }
        };

        const currentLink = computed(() => {
            return isDisabled.value ? null : props.link.href;
        });

        return { isDisabled, currentLink, openAccessModal };
    },
});
</script>

<style lang="scss">
.custom-header__link {
    display: flex;
    align-items: center;
    gap: 4px;

    &.not-access {
        opacity: 0.7;

        &:after {
            display: none;
        }

        &:hover {
            opacity: 0.7;
        }
    }

    .icon {
        svg {
            width: 14px;
            height: 14px;
            object-fit: contain;
        }
    }
}
</style>
