<template>
    <InputSearch
        v-model:data="data"
        :items="items"
        :loading="isLoading"
        :title="i18n.t('header.info.search')"
        clearable
        size="small"
        boldTitle
        @onEnter="goToAllResults"
    >
        <template #button>
            <a :href="`/search/?q=${data}`">
                <CustomButton :text="i18n.t('header.info.all_results')" type="small" />
            </a>
        </template>
    </InputSearch>
</template>

<script>
import { ref, defineComponent, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useApi } from "@/shared/api";
import { api_searchbox } from "../api";
import { debounce, timeout } from "@/shared/lib";

import { CustomButton } from "@/shared/ui";
import InputSearch from "@/entities/InputSearch";

const SearchBar = defineComponent({
    name: "SearchBar",
    components: {
        CustomButton,
        InputSearch,
    },
    setup() {
        const i18n = useI18n();

        const isLoading = ref(false);
        const items = ref([]);
        const data = ref("");

        const getResult = debounce(async (data) => {
            isLoading.value = true;
            const res = await useApi.get({ link: api_searchbox(`?q=${data}`) });
            if (res?.typeGroups) {
                items.value = res.typeGroups;
            }
            isLoading.value = false;
        }, 2000);

        const goToAllResults = () => {
            window.location.href = `/search/?q=${data.value}`;
        };

        watch(data, async (data) => {
            isLoading.value = true;
            await timeout(1000);
            items.value = {};
            getResult(data);
        });

        return {
            i18n,
            data,
            items,
            isLoading,
            goToAllResults,
        };
    },
});

export default SearchBar;
</script>

<style lang="scss">
@import "./assets/search-bar";
</style>
