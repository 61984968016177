import { useNotificationsStore } from "@/shared/model";
let notificationsStore;
const emptyValues = ["{}", "[]", "", "null", undefined, "false"];

/**
 * Initializes the notification store by assigning the value of the useNotificationsStore function to the notificationsStore variable.
 *
 * @return {void} This function does not return a value.
 */
const initNotificationStore = () => {
    notificationsStore = useNotificationsStore();
};

/**
 * Creates a deep copy of the given data object.
 *
 * @param {object} data - The object to be copied.
 * @return {object} The deep copy of the input object.
 */
export const deepCopy = (data) => {
    return typeof data === "object" ? JSON.parse(JSON.stringify(data)) : {};
};

/**
 * Checks if two objects are equal by comparing their stringified representations.
 *
 * @param {any} first - The first object to compare.
 * @param {any} second - The second object to compare.
 * @return {boolean} Returns true if the objects are equal, false otherwise.
 */
export const objectEquality = (first, second) => {
    if (typeof first === "object" && typeof second === "object") {
        return JSON.stringify(first) == JSON.stringify(second);
    }
    return false;
};

/**
 * Checks if the given data is empty.
 *
 * @param {any} data - The data to be checked.
 * @return {boolean} Returns true if the data is empty, false otherwise.
 */
export const isEmpty = (data) => {
    let str = data?.toString();
    if (str == "[object Object]") {
        str = JSON.stringify(data);
    }
    return !str?.length || emptyValues.includes(str);
};

/**
 * Converts a given date into a formatted string representing the day, month, and year.
 *
 * @param {Date|string} date - The date to be converted. It can be either a Date object or a string.
 * @return {string} The formatted date string in the format "Month Day, Year".
 */
export const dateConverter = (date) => {
    const day = new Date(date).getDate();
    const month = new Date(date).toLocaleDateString("en-GB", { month: "short" });
    const year = new Date(date).getFullYear();

    return `${month} ${day}, ${year}`;
};

/**
 * Asynchronously reads the contents of a file and returns a Promise that resolves with the file's base64 representation.
 *
 * @param {File} file - The file to be read.
 * @return {Promise<string|boolean>} A Promise that resolves with the file's base64 representation if successful, or rejects with false if an error occurs.
 */
export const getBase64 = async (file) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve, reject) => {
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = (error) => {
            console.error("Error: ", error);
            reject(false);
        };
    });
};

/**
 * Parses a JSON Web Token (JWT) and returns the decoded payload.
 *
 * @param {string} token - The JWT to be parsed.
 * @return {object} The decoded payload of the JWT.
 */
export const parseJwt = (token) => {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );

    return JSON.parse(jsonPayload);
};

/**
 * Parses the query parameters from the current URL and returns an object containing the decoded values.
 *
 * @param {boolean} [needDecode=true] - Whether to decode the query parameter values. Defaults to true.
 * @return {object} An object containing the decoded query parameter values.
 */
export const parseQuery = (needDecode = true, needString) => {
    let queries = Object.fromEntries([...new URL(window.location).searchParams]);
    if (needDecode && !isEmpty(queries)) {
        for (let key in queries) {
            queries[key] = decodeURIComponent(queries[key]);
        }
    }

    if (needString) {
        queries = Object.entries(queries)
            .map(([key, value]) => `${key}=${value}`)
            .join("&");
    }
    return queries;
};

/**
 * Generates a query string based on the provided data and variables.
 *
 * @param {Object} data - The data object containing the values for the variables.
 * @param {Array} variables - The array of variables to include in the query string.
 *                            Each variable can be either a string or an object with key-value pairs.
 *                            If a variable is an object, only the key-value pairs where the key exists in the data object will be included.
 * @return {string} The generated query string.
 */
export const generateQuery = (data, variables = []) => {
    return variables
        .map((variable) => {
            if (typeof variable === "object") {
                return Object.entries(variable)
                    .filter(([key]) => data[key])
                    .map(([key, value]) => `${value}=${data[key]}`)
                    .join("&");
            } else if (data[variable]) {
                return `${variable}=${data[variable]}`;
            }
            return "";
        })
        .filter(Boolean)
        .join("&");
};

/**
 * Returns the last segment of the current URL's pathname.
 *
 * @return {string} The last segment of the current URL's pathname.
 */
export const getItemId = () => {
    let id = window.location.pathname.split("/").pop();
    if (isNaN(+id)) {
        id = "";
    }
    return id;
};

/**
 * Converts a given size in bytes to a standardized string representation.
 *
 * @param {number} data - The size in bytes to be converted.
 * @return {string} The standardized string representation of the size.
 */
export const standartizeSize = (data) => {
    if (!+data) return "0 Bytes";

    const k = 1024;
    const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(data) / Math.log(k));
    return `${parseFloat((data / Math.pow(k, i)).toFixed(0))} ${sizes[i]}`;
};

/**
 * Standartizes a link by ensuring it starts with a forward slash ("/") or "http(s)://".
 *
 * @param {string} link - The link to be standartized.
 * @return {string|null} The standartized link, or null if the input is not a string.
 */
export const standartizeLink = (link) => {
    if (!link || typeof link !== "string") {
        return null;
    }
    if (link?.length) {
        if (!link?.startsWith("/") && !link?.startsWith("http")) {
            return "/" + link;
        }

        if (link?.startsWith("//")) {
            return link.substring(1);
        }

        return link;
    }
    return "/";
};

/**
 * Returns an array of unique elements from the input data, either based on a specified property name or the elements themselves.
 *
 * @param {Array} data - The input data containing the elements to be processed.
 * @param {string} [name] - An optional property name to use for uniqueness comparison. If not provided, the elements themselves are used for comparison.
 * @return {Array} An array of unique elements from the input data.
 */
export const getUnique = (data, name) => {
    const unique = {};

    data.forEach((el) => {
        if (name) {
            unique[el[name]] = el;
        } else {
            unique[el] = el;
        }
    });

    return Object.values(unique);
};

/**
 * Returns the URL of an image given its path.
 *
 * @param {string} imagePath - The path of the image.
 * @return {URL} The URL of the image.
 */
export const getImageUrl = (imagePath) => {
    return new URL(imagePath, import.meta.url);
};

/**
 * Creates a debounced version of a function that delays its execution until after a specified wait period has passed.
 *
 * @param {Function} callback - The function to be debounced.
 * @param {number} wait - The wait period in milliseconds.
 * @return {Function} - The debounced function.
 */
export const debounce = (callback, wait) => {
    let timeout;

    const executedFunction = (...args) => {
        const later = () => {
            clearTimeout(timeout);
            callback(...args);
        };

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };

    return executedFunction;
};

/**
 * Creates a function that counts the number of times it is called and executes a callback function a specified number of times.
 *
 * @param {Function} callback - The function to be executed.
 * @param {number} count - The number of times the callback function should be executed.
 * @param {Function} errorCallback - The function to be executed if the callback function is not executed the specified number of times.
 * @return {Function} - The counted function.
 */
export const counted = (callback, count, errorCallback) => {
    let attempts = 0;

    initNotificationStore();

    const countedFunction = async (...args) => {
        attempts++;
        if (attempts < count) {
            await callback(...args);
        } else if (attempts == count) {
            notificationsStore.addNotification({
                body: `Something went wrong`,
                type: "error",
                timeless: true,
            });
            if (errorCallback) {
                errorCallback();
            }
            return true;
        }
    };

    return countedFunction;
};

/**
 * Formats a given date into a string representation in the format "YYYY-MM-DD".
 *
 * @param {Date|string} date - The date to be formatted. It can be either a Date object or a string.
 * @return {string} The formatted date string in the format "YYYY-MM-DD".
 */
export const formatDate = (date) => {
    return new Date(date)
        .toLocaleString("en-GB", { year: "numeric", month: "short", day: "2-digit" })
        .split(" ")
        .join("-");
};

/**
 * Asynchronously waits for a specified duration before resolving a Promise.
 *
 * @param {number} duration - The duration in milliseconds to wait before resolving the Promise.
 * @return {Promise<void>} A Promise that resolves after the specified duration.
 */
export const timeout = async (duration) => await new Promise((resolve) => setTimeout(resolve, duration));

/**
 * Downloads a file from the given URL and saves it with the specified filename.
 *
 * @param {string} url - The URL of the file to download.
 * @param {string} filename - The name to save the downloaded file as.
 * @return {Promise<void>} A promise that resolves when the file is successfully downloaded and saved.
 */
export const downloadFile = async (url, filename) => {
    try {
        const response = await fetch(url);

        if (!response.ok) {
            throw new Error(`Unable to download file. HTTP status: ${response.status}`);
        }

        const blob = await response.blob();
        const downloadUrl = URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = downloadUrl;
        downloadLink.download = filename;
        document.body.appendChild(downloadLink);
        downloadLink.click();

        setTimeout(() => {
            URL.revokeObjectURL(downloadUrl);
            document.body.removeChild(downloadLink);
        }, 10000);
    } catch (error) {
        console.error("Error downloading the file:", error.message);
    }
};

/**
 * Parses the given data object and returns a new object with null values replaced by empty strings and nested values extracted.
 *
 * @param {object} data - The data object to be parsed.
 * @return {object} The parsed data object with null values replaced by empty strings and nested values extracted.
 */
export const parseData = (data) => {
    const parsedData = deepCopy(data);
    for (let key in data) {
        if (data[key] == null) {
            parsedData[key] = "";
        }

        const itemData = data[key] ?? "";
        const { value, id } = itemData;
        if (value !== void 0 || id) {
            if (id) {
                parsedData[`${key}_id`] = id;
            }

            parsedData[key] = value;
        }
    }
    return parsedData;
};

/**
 * Splits an array into smaller arrays of a specified size.
 *
 * @param {Array} arr - The array to be chunked.
 * @param {number} size - The size of each chunk.
 * @return {Array} An array of arrays, where each subarray has a maximum length of `size`.
 */
export const chunkArray = (arr, size) => {
    const chunkedArr = [];
    for (let i = 0; i <= arr.length; i += size) {
        chunkedArr.push(arr.slice(i, i + size));
    }

    return chunkedArr;
};

/**
 * Filters out columns from a table based on a list of ignored columns.
 *
 * @param {Object} table - The table object containing the columns.
 * @param {Array} [ignored_columns=[]] - The list of columns to ignore. Defaults to an empty array.
 * @return {Object} The table object with the ignored columns removed.
 */
export const filterIgnoredColumns = (table, ignored_columns = []) => {
    if (ignored_columns.length) {
        table.columns = table.columns.filter((column) => !ignored_columns.some((el) => el == column.field));
    }

    return table;
};

/**
 * Generates a template object based on the provided parameters.
 *
 * @param {Object} template - The template object containing form_template, aside_template, archive_checkboxes, and type.
 * @param {string} pageType - The type of page.
 * @param {string} id - The ID of the page.
 * @param {Array} ignored_columns - The list of columns to ignore.
 * @param {boolean} archive - Whether the page is archived or not.
 * @param {boolean} [needTables=true] - Whether tables are needed in the template or not. Default is true.
 * @return {Object} The generated template object.
 */
export const generateTemplate = (template, pageType, id, ignored_columns, archive, needTables = true, data) => {
    if (!template) {
        return {};
    }
    const { form_template, aside_template, archive_checkboxes, type } = template;
    let { tables } = template;
    let aside = aside_template[pageType];

    aside = typeof aside === "function" ? aside({ ...data, id, archive }) : aside;

    if (tables && needTables) {
        tables = tables.map((table) => filterIgnoredColumns(table, ignored_columns));
    } else {
        tables = [];
    }

    return {
        form: form_template[pageType] || form_template,
        aside,
        tables,
        archive_checkboxes,
        type,
    };
};
/**
 * Generates an edit template based on the provided template.
 *
 * @param {Array|Object} template - The template to generate the edit template from.
 * @return {Array|Object} - The generated edit template.
 */
export const generateEditTemplate = (template) => {
    template = deepCopy(template);

    if (template?.length) {
        template = template.map((el) => {
            return filterTemplate(el);
        });

        template = template.filter((el) => !!el);
    }
    return template;
};

/**
 * Filters out templates based on certain conditions.
 *
 * @param {Object} template - The template object to be filtered.
 * @return {Object|boolean} - The filtered template object or false if the template object is not valid.
 */
const filterTemplate = (template) => {
    if (template?.component.create_only) {
        return false;
    } else if (template?.child?.length) {
        template.child = generateEditTemplate(template.child);
    }

    return template;
};

/**
 * Updates the disabled items in the tabInfo object based on the nextActions and empties flag.
 *
 * @param {Object} tabInfo - The object containing the tab information.
 * @param {boolean} empties - A flag indicating whether to include all nextActions if tabInfo.disabled is empty.
 * @return {Array} The updated disabled items in the tabInfo object.
 */
export const checkDisabledItems = (tabInfo, empties) => {
    const nextActions = tabInfo.buttons.reduce((acc, el) => {
        if (el.type === "next" && el.canDisable) {
            acc.push(el.action);
        }

        return acc;
    }, []);
    if (!tabInfo.disabled) {
        tabInfo.disabled = [];
    }

    const includesLength = tabInfo.disabled?.reduce(
        (acc, value) => (nextActions.some((el) => el === value) ? ++acc : acc),
        0
    );
    if (empties && includesLength < nextActions.length) {
        tabInfo.disabled = [...tabInfo.disabled, ...nextActions];
    } else if (includesLength && !empties) {
        tabInfo.disabled = tabInfo.disabled.filter((el) => !nextActions.includes(el));
    }

    return tabInfo.disabled;
};

/**
 * Generates an object with items grouped by their types from the provided data object.
 *
 * @param {Object} data - The data object containing items grouped by types.
 * @param {Array} [keys=[]] - An optional array of keys to use for mapping item properties.
 * @return {Object} An object with items grouped by their types.
 */
export const changeItemsByTypes = (data, keys = []) => {
    delete data.data;
    delete data.meta;
    const itemsTypes = {};
    for (let key in data) {
        itemsTypes[key] = data[key];
        if (itemsTypes[key] instanceof Object && !Array.isArray(itemsTypes[key])) {
            Object.entries(itemsTypes[key]).forEach(([key, value]) => {
                if (Array.isArray(value)) {
                    itemsTypes[key] = value;
                }
            });
        }
    }
    for (let key in itemsTypes) {
        if (Array.isArray(itemsTypes[key])) {
            itemsTypes[key] = itemsTypes[key]
                .filter((el) => !+el.archive)
                .map((item) => {
                    return {
                        title: item[keys.title] ?? item.title ?? item.value ?? item.key,
                        value: item[keys.value] ?? item.key ?? item.value,
                    };
                });
        }
    }
    return itemsTypes;
};

export const isMobileAgent = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};
