<template>
    <div
        class="toasts__item default toast"
        :class="{
            [`_${notification.type}`]: notification.type,
        }"
    >
        <div class="toast__wrapper">
            <div class="toast__icon">
                <Icon :customIcons="icons" :icon="icons[notification.type] ? notification.type : 'info'" />
            </div>
            <div class="toast__content">
                <p class="toast__title" :class="{ 'mb-5': notification.body }" v-if="notification.head">
                    {{ notification.head }}
                </p>

                <p class="toast__description" v-html="notification.body"></p>

                <CustomButton
                    :text="notification.button.text"
                    :type="notification.type || 'secondary'"
                    small
                    class="toast__link mt-15"
                    icon="right"
                    position="right"
                    v-if="notification.button"
                    @click="goTo(notification.button.link)"
                />
            </div>
        </div>
        <div class="toast__close" @click="deleteNotif(notification.id)" v-if="!notification.unClosable">
            <Icon icon="close" />
        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import * as icons from "./icons";

import { CustomButton, Icon } from "@/shared/ui";
import { useNotificationsStore } from "@/shared/model";
import { useI18n } from "vue-i18n";

const Toast = defineComponent({
    name: "Toast",
    props: {
        notification: {
            type: Object,
            required: true,
        },
    },
    components: {
        Icon,
        CustomButton,
    },
    setup() {
        const i18n = useI18n();
        const notificationStore = useNotificationsStore();

        const deleteNotif = (id) => {
            notificationStore.deleteNotification(id);
        };

        const goTo = (link) => {
            if (link) {
                window.location.href = link;
            }
        };

        return { i18n, icons, deleteNotif, goTo };
    },
});
export default Toast;
</script>

<style lang="scss">
@import "./toast";
</style>
